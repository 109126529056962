// TODO: Add short ISR style check

import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next'
import { getServerSideLocale } from '@telus/next-locale-utilities'

import '@telus-uds/palette-allium/build/web/fonts/fonts-cdn.css'

import sitebuilderConfig from '../../../sitebuilder.config'

import { handleExcludesQueryParam, ValidExcludes } from '@/utils/handleExcludesQueryParam'

import { GenericPage, GenericPageProps } from '../../siteBuilder/components/GenericPage'
import { Language, Locale, Province, removeLocaleFromSlug } from '../../siteBuilder/locale'
import { getGenericPageTemplateFromSlug } from '../../siteBuilder/utils/getGenericPageTemplateFromSlug'
import { ISiteBuilderEntry } from '@/siteBuilder/utils/types/ISiteBuilderEntryTypes'

import { handleRedirect } from '../../siteBuilder/utils/handleRedirect'
import { getLineOfBusiness } from '../../siteBuilder/lineOfBusiness/lineOfBusiness'
import { getContentfulLocale } from '../../siteBuilder/locale/getContentfulLocale'

import {
  setPersonalizationRequestCookies,
  setPersonalizationResponseCookies,
} from '../../siteBuilder/personalization/personalization'

import { IAbTestRedirect, IGenericPageTemplateFields } from '../../../contentful-types'
import logger from '../../logger/logger'
import { handleAuth } from '../../siteBuilder/utils/auth/handleAuth'

export default function TelusPage({ genericPageTemplate, locale, excludes, analyticsScripts }: GenericPageProps) {
  if (!genericPageTemplate) {
    return null
  }

  return (
    <GenericPage
      genericPageTemplate={genericPageTemplate}
      locale={locale}
      excludes={excludes}
      analyticsScripts={analyticsScripts}
    />
  )
}

const getGenericPageTemplate = async (
  slug: string,
  ctx: GetServerSidePropsContext,
  locale: string,
  correlationId: string
): Promise<ISiteBuilderEntry<IGenericPageTemplateFields>> => {
  try {
    const forceContentfulFallbackCache = (ctx.req.headers['force-contentful-fallback-cache'] as string) || 'false'

    const genericPageTemplate = await getGenericPageTemplateFromSlug(
      slug === '' ? '/' : slug,
      sitebuilderConfig.space,
      ctx.req.cookies,
      locale,
      correlationId,
      forceContentfulFallbackCache,
      'genericPageTemplate'
    )
    return genericPageTemplate as ISiteBuilderEntry<IGenericPageTemplateFields>
  } catch (error) {
    console.error(error)
    if (error instanceof Error) {
      // TODO: does this make sense throwing the same thrown error
      throw new Error(error.message)
    }
  }
}

/**
 * The main SSR function
 * Gets the data from contentful using the incoming slug
 */
export const getServerSideProps = async (
  ctx: GetServerSidePropsContext
): Promise<GetServerSidePropsResult<GenericPageProps>> => {
  const { params } = ctx
  const slug = removeLocaleFromSlug(params?.slug)
  let correlationId = ''
  // correlation id is found in different places depending on if nextjs is run via npm run dev or npm start
  // Not sure why :(
  if (ctx.req.headers['correlation-id']) {
    // Required in dev
    correlationId = ctx.req.headers['correlation-id'] as string
  } else if (ctx.res?.getHeader('correlation-id')) {
    // Required in prod
    correlationId = ctx.res?.getHeader('correlation-id') as string
  }

  const serverSideLocale = getServerSideLocale(ctx)
  const locale: Locale = {
    language: serverSideLocale.lang as Language,
    province: serverSideLocale.prov as Province,
    country: 'CA',
  }

  const contentfulLocale = getContentfulLocale(locale)

  ctx.req.cookies = setPersonalizationRequestCookies(ctx)

  let genericPageTemplate: ISiteBuilderEntry<IGenericPageTemplateFields>
  try {
    genericPageTemplate = await getGenericPageTemplate(slug, ctx, contentfulLocale, correlationId)
    logger.debug({
      message: 'Retrieved generic page template from Stitch API',
      application: 'SB2',
      env: process.env.APP_ENV,
      correlationId,
      slug,
      locale: contentfulLocale,
    })
  } catch (error) {
    logger.error({
      message: 'Failed to fetch generic page template',
      env: process.env.APP_ENV,
      error,
      correlationId,
      slug,
      requestUrl: ctx.req.url,
    })
    return {
      notFound: true,
    }
  }

  if (!genericPageTemplate || !genericPageTemplate.fields) {
    return {
      notFound: true,
    }
  }

  if (genericPageTemplate.fields.isAuthRequired) {
    const result = await handleAuth(ctx)
    if (!result.isAuthenticated) {
      return {
        redirect: {
          destination: result.unauthenticatedRedirectUrl,
          permanent: false,
        },
      }
    }
  }

  // Handle redirect
  if (genericPageTemplate.fields.redirect) {
    // Check for region based redirect
    if (
      genericPageTemplate.fields.regions &&
      genericPageTemplate.fields.regions.length > 0 &&
      Boolean(genericPageTemplate.fields.redirectUnsupportedRegions)
    ) {
      // Handle region based redirects - Does the users current region cookie match one of the available regions
      if (!genericPageTemplate.fields.regions.includes(locale.province as Lowercase<Province>)) {
        return {
          redirect: handleRedirect(genericPageTemplate.fields.redirect.fields),
        }
      }
    } else {
      return {
        redirect: handleRedirect(genericPageTemplate.fields.redirect.fields),
      }
    }
  }

  // TODO: is IAbTestRedirect in generic page template?
  // Try to find an abRedirect Block on the page
  const abRedirectBlock = genericPageTemplate.fields.blocks.find(
    // @ts-ignore
    (block): block is IAbTestRedirect => block?.sys?.contentType?.sys?.id === 'abRedirectId'
  ) as unknown as IAbTestRedirect | undefined

  // Handle A/B Test Redirect
  if (abRedirectBlock) {
    const pageRedirectionUrl = abRedirectBlock.fields.url
    return {
      redirect: {
        destination: pageRedirectionUrl,
        permanent: false,
        basePath: false,
      },
    }
  }

  // Handle personalization
  const {
    mboxResponses,
  }: {
    mboxResponses: { [key: string]: string }
  } = genericPageTemplate.fields as any
  const setCookieHeaders = setPersonalizationResponseCookies(mboxResponses)
  if (setCookieHeaders.length > 0) {
    ctx.res.setHeader('Set-Cookie', setCookieHeaders)
  }

  // Check the exclude query param to see if we should exclude any functionality
  // Supported excludes are 'ge' and 'analytics'
  // They may be passed together as a comma separated list
  // Ex. ?exclude=ge,analytics
  let excludes: Partial<Record<ValidExcludes, boolean>> = null
  if (process.env.APP_ENV !== 'production') {
    excludes = handleExcludesQueryParam(ctx.query?.excludes as string)
  }

  return {
    props: {
      genericPageTemplate,
      locale,
      excludes,
      analyticsScripts: getLineOfBusiness(slug).analyticsScripts,
      brand: 'telus',
    },
  }
}
